import common from '@/common'

let form_options = {}
form_options.company = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
  ].forEach(arr => arr.length = 0)

  common.apiGetData('/company/GetCompanyDropDownList')
    .then(res => {
      const company_options = common.getSelectOptions(res.data.companys, 'companyName', 'companyID')
      form_options.company.push.apply(form_options.company, company_options)
    })
}

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    options: form_options.company,
    rule: 'required',
    edit_disable: true,
    add_disable: !common.isAdmin()
  },
  {
    name: 'facilityRoleIDs',
    label: 'common.facility',
    type: 'slot',
    show: 'companyID',
    slot: 'facility',
    rule: 'required',
    custom_messages: {
      required: common.getI18n('error.StaffRoleIDRequired')
    }
  },
  {
    name: 'staffCode',
    label: 'staff.staffCode',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'loginName',
    label: 'staff.loginName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'initPassword',
    label: 'staff.initPassword',
    type: 'text',
    rule: 'required|max: 20',
    add_show: true
  },
  {
    name: 'chineseName',
    label: 'staff.chineseName',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'englishName',
    label: 'staff.englishName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'contactEmail',
    label: 'staff.contactEmail',
    type: 'text',
    rule: 'max: 50|email',
  },
  {
    name: 'contactPhone',
    label: 'staff.contactPhone',
    type: 'text',
    rule: 'max: 20|digits: 8',
  },
  {
    name: 'isActive',
    label: 'staff.isActive',
    type: 'select',
    rule: 'required',
    options: [
      {
        text: common.getI18n('staff.active'),
        value: true
      },
      {
        text: common.getI18n('staff.passive'),
        value: false
      },
    ],
  },

  {
    type: 'column-separator'
  },

  {
    label: 'common.login_associated',
    type: 'title',
    add_show: false,
    edit_show: true,
  },
  {
    name: 'loginNfcs',
    label: 'staff.login_nfc',
    type: 'slot',
    validate_in_slot: true,
    slot: 'loginNfcs',
    slot_config: {
      name: 'loginNfcs',
      label: 'staff.login_nfc',
      type: 'text',
      min_count: 1,
      edit_disable: true,
      input_class: 'text-monospace',
      check_duplicate: false,
    },
    add_show: false,
    edit_show: true,
  },

  // {
  //   name: 'callBellNfcTitle',
  //   label: 'facility.call_bell_config',
  //   type: 'title',
  //   add_show: false,
  //   edit_show: false,
  // },
  // {
  //   name: 'callBellNfcs',
  //   label: 'staff.call_bell_nfc',
  //   type: 'slot',
  //   validate_in_slot: true,
  //   // rule: 'required',
  //   slot: 'callBellNfcs',
  //   slot_config: {
  //     name: 'Nfcs',
  //     label: 'staff.call_bell_nfc',
  //     type: 'text',
  //     min_count: 0,
  //     max_count: 99,
  //     edit_disable: true,
  //     input_class: 'text-monospace',
  //     check_duplicate: true,
  //     // rule: {}, // written in slot
  //     show_del_btn: true,
  //     show_add_btn: true,
  //   },
  //   add_show: false,
  //   edit_show: false,
  // }
]
